import React from 'react';
import { useGetDronesQuery } from '@/features/api/copApiEndpoints/droneApi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAppSelector } from '@/app/hooks';
import styles from './DroneTab.module.scss';
import { Autocomplete, Button } from '@mui/joy';
import { hubType } from '@/app/stores/hubSlice';
import { usePutDroneMutation } from '@/features/api/copApiEndpoints/droneApi';
import { useNavigate } from 'react-router-dom';

export default function DroneTab() {
  const { data: drones, isLoading, refetch } = useGetDronesQuery('');
  const hubState = useAppSelector((state) => state.hub) as { hubList: hubType[] };
  const [selectedHubs, setSelectedHubs] = React.useState<{ [key: string]: hubType | null }>({});
  const [putDrone] = usePutDroneMutation();
  const [relocateLoading, setRelocateLoading] = React.useState(false);
  const [editingDrone, setEditingDrone] = React.useState<string | null>(null);
  const navigate = useNavigate();

  const handleRelocate = async (droneReference: string, targetHub: hubType) => {
    setRelocateLoading(true);
    try {
      await putDrone({
        params: { reference: droneReference },
        body: { currentHubId: targetHub.id }
      }).unwrap();
      
      // Refresh the drone list to show updated values
      await refetch();
      setEditingDrone(null);
    } finally {
      setRelocateLoading(false);
    }
  };

  const handleBatteryAction = (droneId: string) => {
    navigate(`/drones/${droneId}/battery-swap-checklist`);
  };

  const handleCallsignAction = (droneId: string) => {
    navigate(`/drones/${droneId}/start-of-day-checklist`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <TableContainer 
      component={Paper} 
      style={{
        backgroundColor: styles.tabBgColor, 
        border: styles.tabBorder, 
        borderRadius: styles.tabBorderRadius
      }}
    >
      <Table aria-label="drone table">
        <TableHead>
          <TableRow style={{borderBottom: 'unset'}}> 
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Reference</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Call Sign</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Model</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Status</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Current Hub</TableCell>
            <TableCell style={{borderBottom: 'unset', color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize}}>Batteries</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(drones) && drones
            .slice()
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map((drone) => {
            const currentHub = hubState.hubList.find((hub: hubType) => hub.id === drone.currentHubId);
            return (
              <TableRow key={drone.reference}>
                <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.reference}</TableCell>
                <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>
                  {drone.callSign}
                  <Button
                    variant="plain"
                    size="sm"
                    sx={{ ml: 1, p: 0.5 }}
                    onClick={() => handleCallsignAction(drone.reference)}
                  >
                    ⚙️
                  </Button>
                </TableCell>
                <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.model}</TableCell>
                <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>{drone.loaded ? 'Loaded' : 'Empty'}</TableCell>
                <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>
                  <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    {editingDrone === drone.reference ? (
                      <Autocomplete
                        open
                        options={hubState.hubList}
                        getOptionLabel={(option) => option.name}
                        value={currentHub}
                        onChange={(_, newValue) => {
                          if (newValue) {
                            handleRelocate(drone.reference, newValue);
                          }
                        }}
                        size="sm"
                        sx={{ width: 150 }}
                        autoFocus
                        placeholder="Select Hub"
                        loading={relocateLoading}
                      />
                    ) : (
                      <>
                        {currentHub?.name || 'Unknown Hub'}
                        <Button
                          variant="plain"
                          size="sm"
                          onClick={() => {
                            setSelectedHubs(prev => {
                              const updated = { ...prev };
                              updated[drone.reference] = currentHub??null;
                              return updated;
                            });
                            setEditingDrone(drone.reference);
                          }}
                          disabled={relocateLoading}
                          sx={{ p: 0.5, ml: 1 }}
                        >
                          ✏️
                        </Button>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell style={{borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily}}>
                  {drone.currentBatteries}
                  <Button
                    variant="plain"
                    size="sm"
                    sx={{ ml: 1, p: 0.5 }}
                    onClick={() => handleBatteryAction(drone.reference)}
                  >
                    ⚡
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
