import React, { useState } from 'react';
import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';
import { useGetDroneQuery } from '@/features/api/copApiEndpoints/droneApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlane, 
  faBatteryHalf, 
  faClipboardCheck,
  faCircleCheck,
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { Button, Snackbar } from '@mui/joy';
import DroneTab from './components/DroneTab';
import styles from './Drones.module.scss';

export function Drones() {
  const [snackbarSuccess, setSnackbarSuccess] = useState<boolean>(false);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);

  return (
    <MainLayout>
      <div className={styles.body}>
        <DroneTab />

        <Snackbar // Success notification
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={4000}
          open={snackbarSuccess}
          variant='soft'
          color='success'
          onClose={() => setSnackbarSuccess(false)}
          endDecorator={
            <Button
              onClick={() => setSnackbarSuccess(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleCheck}/> Operation completed successfully!
        </Snackbar>

        <Snackbar // Error notification
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={4000}
          open={snackbarError}
          variant='soft'
          color='danger'
          onClose={() => setSnackbarError(false)}
          endDecorator={
            <Button
              onClick={() => setSnackbarError(false)}
              size="sm"
              variant="soft"
              color="danger"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleExclamation}/> Operation failed!
        </Snackbar>
      </div>
    </MainLayout>
  );
}