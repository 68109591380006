import { copAPI } from '../copAPI';

export type DroneData = {
  reference: string;
  callSign: string;
  model: string;
  currentHubId?: string;
  currentBatteries: string;
  lastBatteryChange?: string;
  loaded:boolean;
  batterySwapChecklistUrl?: string;
  startOfDayChecklistUrl?: string;
};

export type putDroneBody = {
  currentHubId: string;
}

export type putDroneParams = {
  reference: string
};

export const droneApi = copAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDrone: builder.query<DroneData | DroneData[], string>({
      query: (reference) => ({
        url: `./drones/${reference}`,
        method: 'GET',
      }),
    }),
    getDrones: builder.query<DroneData | DroneData[], string>({
      query: () => ({
        url: `./drones`,
        method: 'GET',
      }),
    }),
    putDrone: builder.mutation({
      query: ({params, body}:{params: putDroneParams, body: putDroneBody}) => ({
        url: `./drones/${params.reference}`,
        method: 'PUT',
        params: params,
        body: body,
      }),
    }),
  }),
});

export const { useGetDroneQuery, usePutDroneMutation, useGetDronesQuery } = droneApi;
